import { html } from "lit-html";
import { renderCssClasses } from "Helper/renderHelper/renderCssClasses";

type RenderIconArgs = {
  cssClasses: string;
  iconId: string;
};

export const renderIcon = (args: RenderIconArgs) => {
  return html`
    <odep-e-icon class="odep-e-icon${renderCssClasses(args.cssClasses)}" icon-id="${args.iconId}"></odep-e-icon>
  `;
};
