import { html } from "lit-html";

import { OdepRangeSlider } from "./odep-e-range-slider";

export type rangeSlideTemplateArgs = {
  rangeSlider: OdepRangeSlider;
  min: number;
  max: number;
  step: number;
};

const getCurrentOffset = (args: rangeSlideTemplateArgs) => {
  const currentProgress = (args.rangeSlider.currentValue - args.min) / (args.max - args.min);
  return `${currentProgress * 100}%`;
};

const getCurrentThumbOffset = (args: rangeSlideTemplateArgs) => {
  const currentProgress = (args.rangeSlider.currentValue - args.min) / (args.max - args.min);
  const thumbOffset = 16 * currentProgress;
  return `calc(${currentProgress * 100}% - ${thumbOffset}px + 8px)`;
};

export const rangeSliderTemplate = (args: rangeSlideTemplateArgs) => {
  const currentOffset = getCurrentOffset(args);
  return html`
    <div class="odep-e-range-slider__slider">
      <input
        type="range"
        class="odep-e-range-slider__input"
        min="${args.min}"
        max="${args.max}"
        value="${args.rangeSlider.currentValue}"
      />
      <span class="odep-e-range-slider__progress" style="width: ${currentOffset}"></span>
      <span class="odep-e-range-slider__thumb" style="left: ${getCurrentThumbOffset(args)}"></span>
    </div>
  `;
};
