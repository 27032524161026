import { Component, uiElement, uiEvent } from "@kluntje/core";
import { ODEPIcon } from "Components/odep-e-icon/odep-e-icon";

export class ODEPPlayToggle extends Component {
  private _paused = true;

  @uiElement(".odep-e-play-toggle__btn")
  btn: HTMLButtonElement;

  @uiElement(".odep-e-play-toggle__icon")
  icon: ODEPIcon;

  get paused(): boolean {
    return this._paused;
  }

  set paused(newValue) {
    if (newValue === this.paused) return;

    if (newValue === true) {
      this.icon.iconId = "odep-icon-play";
    } else {
      this.icon.iconId = "odep-icon-pause";
    }

    this._paused = newValue;
  }

  @uiEvent("btn", "click")
  handleBtnClick() {
    this.togglePlaybackState();
  }

  togglePlaybackState() {
    this.paused = !this.paused;
  }
}

customElements.define("odep-e-play-toggle", ODEPPlayToggle);
