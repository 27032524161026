import { Component, uiElement, uiEvent } from "@kluntje/core";
import { getParent } from "@kluntje/js-utils/lib/dom-helpers";
import { ODEPLazyImage } from "Components/odep-e-lazy-image/odep-e-lazy-image";
import { ODEPPlayToggle } from "Components/odep-e-play-toggle/odep-e-play-toggle";
import {
  AudioTrackCoverImageOptions,
  AudioTrackOptions,
} from "Components/odep-m-audio-player-component/odep-m-audio-player-component";
import { STATE_CHANGED_EVENT } from "Constants/eventTypes";

export class ODEPAudioPlayerTrack extends Component {
  @uiElement(".odep-m-audio-player-track__cover-image")
  cover: ODEPLazyImage;

  @uiElement(".odep-m-audio-player-track__title")
  titleElement: HTMLElement;

  @uiElement(".odep-m-audio-player-track__description")
  descriptionElement: HTMLElement;

  @uiElement(".odep-m-audio-player-track__play-toggle")
  playToggle: ODEPPlayToggle;

  get audioSrc(): string {
    return this.getAttribute("audio-src") || "";
  }

  get paused() {
    return this.playToggle.paused;
  }

  get coverImageOptions(): AudioTrackCoverImageOptions {
    return {
      alt: this.cover.getAttribute("alt") || "",
      src: this.cover.getAttribute("src") || "",
      srcSet: this.cover.getAttribute("srcset") || "",
    };
  }

  get trackOptions(): AudioTrackOptions {
    return {
      cover: this.coverImageOptions,
      audioSrc: this.audioSrc,
      title: this.titleElement.innerText,
      description: this.descriptionElement.innerText,
    };
  }

  @uiEvent("this", "click")
  handleClick(e: MouseEvent) {
    const clickTarget = e.target;

    if (clickTarget !== null && getParent(clickTarget as Element, ".odep-e-play-toggle") !== null) {
      this.dispatchEvent(new CustomEvent(STATE_CHANGED_EVENT, { bubbles: false }));
      return;
    }

    this.playToggle.togglePlaybackState();
    this.dispatchEvent(new CustomEvent(STATE_CHANGED_EVENT, { bubbles: false }));
  }

  updatePausedState(isPaused: boolean) {
    this.playToggle.paused = isPaused;
  }
}

customElements.define("odep-m-audio-player-track", ODEPAudioPlayerTrack);
