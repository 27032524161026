import { render } from "lit-html";
import { Component, uiElement, uiEvent } from "@kluntje/core";
import { STATE_CHANGED_EVENT } from "Constants/eventTypes";

import { rangeSliderTemplate, rangeSlideTemplateArgs } from "./odep-e-range-slider.template";

export class OdepRangeSlider extends Component {
  @uiElement(".odep-e-range-slider__input")
  sliderInput: HTMLInputElement;

  private isDragging = false;

  static get observedAttributes() {
    return ["max"];
  }

  attributeChangedCallback(name: string, oldValue: any, newValue: any) {
    if (oldValue === newValue) return;
    this.updateComponent();
  }

  get min(): number {
    return parseInt(this.getAttribute("min") || "0", 10);
  }

  get max(): number {
    const fallbackValueString = (this.min + 1).toString();
    return parseInt(this.getAttribute("max") || fallbackValueString || "", 10);
  }

  get step(): number {
    return parseInt(this.getAttribute("step") || "1", 10);
  }

  get initialValue(): number {
    const fallbackValueString = (this.min + 1).toString();
    return parseInt(this.getAttribute("initial-value") || fallbackValueString || "", 10);
  }

  get currentValue(): number {
    if (this.sliderInput === undefined) return this.initialValue;
    return parseInt(this.sliderInput.value || "0", 10);
  }

  set currentValue(newValue) {
    if (this.currentValue === newValue) return;
    this.sliderInput.value = newValue.toString();
    this.renderComponent();
  }

  get renderArgs(): rangeSlideTemplateArgs {
    return {
      rangeSlider: this,
      min: this.min,
      max: this.max,
      step: this.step,
    };
  }

  @uiEvent("sliderInput", "mousemove touchmove change")
  updateComponent() {
    this.renderComponent();
    if (this.isDragging === false) return;
    this.dispatchEvent(new CustomEvent(STATE_CHANGED_EVENT, { bubbles: false }));
  }

  @uiEvent("sliderInput", "mousedown touchstart")
  handleDragStart() {
    this.isDragging = true;
  }

  @uiEvent("sliderInput", "mouseup touchend")
  handleDragEnd() {
    this.isDragging = false;
  }

  @uiEvent("sliderInput", "click")
  handleClick() {
    this.dispatchEvent(new CustomEvent(STATE_CHANGED_EVENT, { bubbles: false }));
  }

  renderComponent(): void {
    render(rangeSliderTemplate(this.renderArgs), this.getUiRoot());
  }

  afterComponentRender() {
    this.renderComponent();
  }
}

customElements.define("odep-e-range-slider", OdepRangeSlider);
