// odep events
export const COOKIE_CHANGE = "odep-cookie-change";
export const AEM_MODE_CHANGE = "odep-aem-mode-change";
export const IMAGE_LOAD = "odep-image-load";
export const PAGINATION_CHANGED = "odep-pagination-changed";
export const TAB_CHANGED_EVENT = "odep-tab-changed";
export const FILTER_CHANGED = "odep-filter-changed";
export const CATEGORY_CHANGED = "odep-category-changed";
export const COMPONENT_RESET = "odep-component-reset";
export const EVENT_AEM_MODE_CHANGE = "odep-aem-mode-changed";
export const MAP_LOADED_EVENT = "odep-map-loaded";
export const OVERLAY_CLOSED = "odep-overlay-closed";
export const MAP_ACTIVE_MARKER_CHANGED_EVENT = "odep-active-marker-changed";

// Form Events
export const CHECKED_EVENT = "odep-form-field-checked";
export const CHANGED_EVENT = "odep-form-field-changed";
export const STATE_CHANGED_EVENT = "odep-state-changed";
export const SELECTION_CHANGED_EVENT = "odep-form-field-checked";
export const SEARCH_INPUT_SUBMIT_EVENT = "odep-search-input-submit";
