import { Component, uiElement, uiEvent } from "@kluntje/core";
import { ODEPIcon } from "Components/odep-e-icon/odep-e-icon";
import { ODEPPlayToggle } from "Components/odep-e-play-toggle/odep-e-play-toggle";
import { OdepRangeSlider } from "Components/odep-e-range-slider/odep-e-range-slider";
import { STATE_CHANGED_EVENT } from "Constants/eventTypes";
import { render } from "lit-html";

export class ODEPAudioPlayer extends Component {
  @uiElement(".odep-m-audio-player__audio")
  audioPlayer: HTMLAudioElement;

  @uiElement(".odep-m-audio-player__seeker-slider")
  seeker: OdepRangeSlider;

  @uiElement(".odep-m-audio-player__play-btn")
  playBtn: ODEPPlayToggle;

  @uiElement(".odep-m-audio-player__play-icon")
  playIcon: ODEPIcon;

  private isSeeking = false;

  constructor() {
    super({
      asyncRendering: true,
    });
  }

  get audioSrc(): string {
    return this.getAttribute("audio-src") || "";
  }

  get currentTime(): number {
    if (this.audioPlayer === undefined) return 0;
    return Math.round(this.audioPlayer.currentTime);
  }

  get audioDuration(): number {
    if (this.audioPlayer === undefined) return 0;
    return Math.round(this.audioPlayer.duration);
  }

  get paused() {
    return this.audioPlayer.paused;
  }

  async renderAsync() {
    const audioPlayerTemplate = await this.loadTemplate();
    render(
      audioPlayerTemplate({
        audioSrc: this.audioSrc,
        currentTime: this.currentTime,
        audioDuration: this.audioDuration,
      }),
      this,
    );
  }

  async loadTemplate() {
    const { audioPlayerTemplate } = await import("./odep-m-audio-player.template");
    return audioPlayerTemplate;
  }

  loadAudioFile(url: string, autoplay = true) {
    this.audioPlayer.autoplay = autoplay;
    this.playBtn.paused = !autoplay;
    this.audioPlayer.src = url;
  }

  @uiEvent("playBtn", "click")
  handlePlayBtnClick() {
    if (this.audioPlayer.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  @uiEvent("audioPlayer", "loadedmetadata")
  handleAudioFileLoaded() {
    this.renderAsync();
  }

  @uiEvent("audioPlayer", "timeupdate")
  handleAudioTimeupdate() {
    this.renderAsync();
    if (this.isSeeking) {
      this.isSeeking = false;
      return;
    }
    this.seeker.currentValue = this.currentTime;
  }

  @uiEvent("audioPlayer", "ended")
  handleAudioEnded() {
    this.pause();
    this.renderAsync();
  }

  @uiEvent("seeker", STATE_CHANGED_EVENT)
  handleSeekerUpdate() {
    this.isSeeking = true;
    if (this.seeker.currentValue === this.currentTime) return;
    this.audioPlayer.currentTime = this.seeker.currentValue;
  }

  play() {
    this.audioPlayer.play();
    this.playBtn.paused = false;
    this.dispatchEvent(new CustomEvent(STATE_CHANGED_EVENT, { bubbles: false }));
  }

  pause() {
    this.audioPlayer.pause();
    this.playBtn.paused = true;
    this.dispatchEvent(new CustomEvent(STATE_CHANGED_EVENT, { bubbles: false }));
  }

  setPlaybackState(paused: boolean) {
    if (paused === true) {
      this.pause();
    } else {
      this.play();
    }
  }
}

customElements.define("odep-m-audio-player", ODEPAudioPlayer);
