// Prefixes
export const STATE_PREFIX = "odep-state-";

// Helper
export const TOUCH = "odep-h-touch";
export const NO_TOUCH = "odep-h-no-touch";
export const INVERTED = "odep-h-inverted";

// States
export const ACTIVE = "odep-state-active";
export const LOADING = "odep-state-loading";
export const OPEN = "odep-state-open";
export const LOADED = "odep-state-loaded";
export const ERROR = "odep-state-error";
export const HIDDEN = "odep-state-hidden";
export const VISIBLE = "odep-state-visible";
export const INVISIBLE = "odep-state-invisible";
export const INACTIVE = "odep-state-inactive";
export const UNSTARTED = "odep-state-unstarted";
export const SCROLL_LOCK = "odep-state-scroll-lock";
export const ANIMATE = "odep-state-animate";
export const ANIMATING = "odep-state-animating";
export const IN_VIEWPORT = "odep-state-invp";
export const DRAGGING = "odep-state-dragging";
export const SCROLLING = "odep-state-scrolling";
export const MINIMAL = "odep-state-minimal";
export const UP = "odep-state-up";
export const DOWN = "odep-state-down";
export const DISABLED = "odep-state-disabled";
export const SELECTED = "odep-state-selected";
export const CAN_SUBMIT = "odep-state-can-submit";
export const HAS_OVERFLOW = "odep-state-has-overflow";
export const HASCOOKIE = "odep-state-has-cookie";
export const INITIALIZED = "odep-state-initialized";
export const OVERLAY_OPEN = "odep-state-overlay-open";
export const SUCCESS = "odep-state-success";
export const PLAYING = "odep-state-playing";
export const FINISHED = "odep-state-finished";
export const STICKY = "odep-state-sticky";
export const KEYBOARD_FOCUS = "odep-h-keyboard-focus";
export const FOCUS = "odep-state-focus";
export const CAN_SCROLL_RIGHT = "odep-state-can-scroll-right";
export const CAN_SCROLL_LEFT = "odep-state-can-scroll-left";
export const HIDDEN_HEADER = "odep-state-hidden-header";
export const CUSTOMER_MODE_B2B = "odep-customer-mode-b2b";
export const CUSTOMER_MODE_B2C = "odep-customer-mode-b2c";
