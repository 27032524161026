import { Component, uiElement, uiElements, uiEvent } from "@kluntje/core";
import { ODEPLazyImage } from "Components/odep-e-lazy-image/odep-e-lazy-image";
import { ODEPAudioPlayerTrack } from "Components/odep-m-audio-player-track/odep-m-audio-player-track";
import { ODEPAudioPlayer } from "Components/odep-m-audio-player/odep-m-audio-player";
import { STATE_CHANGED_EVENT } from "Constants/eventTypes";

export interface AudioTrackCoverImageOptions {
  src: string;
  srcSet?: string;
  alt: string;
}
export interface AudioTrackOptions {
  cover: AudioTrackCoverImageOptions;
  title: string;
  description: string;
  audioSrc: string;
}
export class ODEPAudioPlayerComponent extends Component {
  private _currentAudioTrack: ODEPAudioPlayerTrack | null = null;

  @uiElement(".odep-m-audio-player-component__title")
  titleEl: HTMLHeadingElement;

  @uiElement(".odep-m-audio-player-component__description")
  descriptionEl: HTMLElement;

  @uiElement(".odep-m-audio-player-component__cover-image")
  coverImage: ODEPLazyImage;

  @uiElement(".odep-m-audio-player-component__audio-player")
  audioPlayer: ODEPAudioPlayer;

  @uiElements(".odep-m-audio-player-component__track")
  audioTracks: Array<ODEPAudioPlayerTrack>;

  get currentAudioTrack() {
    return this._currentAudioTrack;
  }

  set currentAudioTrack(newTrack) {
    if (this.currentAudioTrack === newTrack) {
      if (newTrack !== null) this.audioPlayer.setPlaybackState(newTrack.paused);
      return;
    }

    if (this.currentAudioTrack !== null) {
      this.currentAudioTrack.updatePausedState(true);
    }

    if (newTrack !== null) {
      this.loadTrack(newTrack);
    }

    this._currentAudioTrack = newTrack;
  }

  loadTrack(track: ODEPAudioPlayerTrack) {
    const trackOptions = track.trackOptions;
    this.titleEl.innerText = trackOptions.title;
    this.descriptionEl.innerText = trackOptions.description;
    this.audioPlayer.loadAudioFile(trackOptions.audioSrc);
    this.coverImage.setAttribute("src", trackOptions.cover.src);
    this.coverImage.setAttribute("srcset", trackOptions.cover.srcSet || "");
    this.coverImage.setAttribute("alt", trackOptions.cover.alt);
  }

  @uiEvent("audioTracks", STATE_CHANGED_EVENT)
  handleTrackClick(e: MouseEvent) {
    const clickedTrack = e.currentTarget as ODEPAudioPlayerTrack;

    this.currentAudioTrack = clickedTrack;
  }

  @uiEvent("audioPlayer", STATE_CHANGED_EVENT)
  handleAudioPlayerStateChange() {
    if (this.currentAudioTrack === null) {
      this._currentAudioTrack = this.audioTracks[0] || null;
    }

    if (this.currentAudioTrack !== null) this.currentAudioTrack.updatePausedState(this.audioPlayer.paused);
  }
}

customElements.define("odep-m-audio-player-component", ODEPAudioPlayerComponent);
